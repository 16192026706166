<template>
  <b-modal
    @shown="updateItem"
    :title="
      `查看畢業分數 - 編號:${item.code}    身分證號:${item.id}    姓名:${item.name}`
    "
    id="ruralNursingDataGraduScoreOne"
    size="xl"
  >
    <b-container fluid>
      <main class="main-grid-dtc">
        <b-input-group prepend="年度">
          <b-input v-model="item.graduateYear" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input v-model="item.id" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="學生姓名">
          <b-input v-model="item.name" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="學校名稱">
          <b-input v-model="item.school" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="兵役狀況" style="grid-column: 2/-1;">
          <!-- <b-input v-model="personScoreData.VeteranStatus"></b-input> -->
          <b-select
            :options="millitaryOptions"
            v-model="personScoreData.VeteranStatus"
          ></b-select>
        </b-input-group>
        <!-- <b-input-group prepend="個人級分" style="grid-column: 1/-1;">
          <b-input v-model="personScoreData.Result" readonly></b-input>
        </b-input-group>
        <b-input-group prepend="排名" style="grid-column: 1/-1;">
          <b-input v-model="personScoreData.Rank" readonly></b-input>
        </b-input-group> -->
        <b-input-group prepend="個人成績">
          <b-input
            v-model="personScoreData.Score"
            @keypress="isNumber(personScoreData.Score, $event)"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="同屆最高成績">
          <b-input
            v-model="personScoreData.MaxScore"
            @keypress="isNumber(personScoreData.MaxScore, $event)"
          ></b-input>
        </b-input-group>
        <!-- <b-input-group prepend="個人-最低" style="grid-column: 2/-1;">
          <b-input v-model="personScoreData.ScoreSubMin" readonly></b-input>
        </b-input-group> -->
        <b-input-group prepend="同屆最低成績">
          <b-input v-model="personScoreData.MinScore"></b-input>
        </b-input-group>

        <!-- <b-input-group prepend="最高-最低" style="grid-column: 2/-1;">
          <b-input v-model="personScoreData.MaxSubMin" readonly></b-input>
        </b-input-group> -->

        <b-input-group prepend="身分證正反影本">
          <b-select
            :options="['是', '否']"
            v-model="personScoreData.IdentityCheck"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="兵役文件">
          <b-select
            :options="['是', '否']"
            v-model="personScoreData.VeteranCheck"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="成績單文件">
          <b-select
            :options="['是', '否']"
            v-model="personScoreData.TranscriptCheck"
          ></b-select>
        </b-input-group>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('ruralNursingDataGraduScoreOne')"
          >取消</b-button
        >
        <b-button
          variant="success"
          class="float-right ml-3"
          @click="updatePersonInfo"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { yearsObject } from "@/constant.js";
import { store } from "@/store/global.js";
import moment from "moment";
const millitaryOptions = [
  { value: "A", text: "未服役" },
  { value: "B", text: "退伍" },
  { value: "C", text: "免役" },
  { value: "D", text: "女性免役" },
];

export default {
  name: "RuralNursingDataGraduScoreOne",
  data() {
    return {
      item: { ...store.editItem },
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      personScoreData: {
        ContentAddress: "",
        Email: "",
        Flag: false,
        Identifier: "",
        IdentityCheck: "",
        MaxScore: "",
        MaxSubMin: "",
        MinScore: "",
        Mobile: "",
        Name: "",
        Phone: "",
        Rank: "",
        RegAddress: "",
        Result: "",
        SchoolCode: "",
        Score: "",
        ScoreSubMin: "",
        TranscriptCheck: "",
        VeteranCheck: "",
        VeteranStatus: "",
        YearTerm: "",
        isNewScore: false,
      },
      millitaryOptions,
    };
  },
  computed: {},
  methods: {
    async updatePersonInfo() {
      let obj = {
        ContentAddress: this.item.contactAddress || "",
        Email: this.item.email || "",
        Identifier: this.item.id || "",
        IdentityCheck: this.personScoreData.IdentityCheck || "",
        MaxScore: +this.personScoreData.MaxScore || 0,
        // MaxSubMin:
        //   +this.personScoreData.MaxScore - +this.personScoreData.MinScore || 0,
        MinScore: +this.personScoreData.MinScore || 0,
        Mobile: this.item.mobilephone || "",
        Name: this.item.name || "",
        Phone: this.item.telphone || "",
        // Rank: +this.personScoreData.Rank || 0,
        RegAddress: this.item.homeAddress || "",
        // Result: +this.personScoreData.Result || 0,
        SchoolCode: this.item.schoolCode || "",
        SchoolName: this.item.school || "",
        Score: +this.personScoreData.Score || 0,
        // ScoreSubMin:
        //   +this.personScoreData.Score - +this.personScoreData.MinScore || 0,
        TranscriptCheck: this.personScoreData.TranscriptCheck || "",
        VeteranCheck: this.personScoreData.VeteranCheck || "",
        VeteranStatus: this.personScoreData.VeteranStatus || "",
        YearTerm: this.item.graduateYear || "",
      };
      try {
        this.isNewScore
          ? await window.axios.post(`api/OrderHeadScore`, obj)
          : await window.axios.put(`api/OrderHeadScore`, obj);
        store.editItem = { ...obj };
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$bvModal.hide("ruralNursingDataGraduScoreOne");
      } catch (e) {
        this.$bvToast.toast(e + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    updateItem() {
      this.item = {};
      this.item = { ...store.editItem };
      this.getData();
    },
    async getData() {
      const url = `OrderHeadScore/GetNurseScore?$filter=YearTerm eq '${this.item.graduateYear}' and Identifier eq '${this.item.id}'`;
      try {
        const { Count, Items } = await axios.get(url);
        if (+Count > 0) {
          this.isNewScore = false;
          this.personScoreData = { ...Items[0] };
        } else {
          this.isNewScore = true;
          this.personScoreData = {
            ContentAddress: "",
            Email: "",
            Flag: false,
            Identifier: "",
            IdentityCheck: "",
            MaxScore: "",
            MaxSubMin: "",
            MinScore: "",
            Mobile: "",
            Name: "",
            Phone: "",
            Rank: "",
            RegAddress: "",
            Result: "",
            SchoolCode: "",
            Score: "",
            ScoreSubMin: "",
            TranscriptCheck: "",
            VeteranCheck: "",
            VeteranStatus: "",
            YearTerm: "",
          };
        }
      } catch (error) {
        this.$bvToast.toast(error + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    isNumber(num, evt) {
      let value = num.toString();
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (value.includes(".") && charCode == 46) {
        evt.preventDefault();
      } else if (charCode == 46) {
        return true;
      } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {},
  watch: {
    "personScoreData.Score"(newValue) {
      let value = newValue.toString();

      if (Boolean(newValue) && value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      let number = "";
      if (value.includes(".")) {
        let num1 = value.split(".")[0].substring(0, 2);
        let num2 = value.split(".")[1].substring(0, 2);
        number = `${num1}.${num2}`;
      } else if (+value == 100) {
        number = 100;
      } else if (+value == 1000) {
        number = 100;
      } else {
        number = value.substring(0, 2);
      }
      const result = number;
      this.$nextTick(() => (this.personScoreData.Score = result));
    },
    "personScoreData.MaxScore"(newValue) {
      let value = newValue.toString();

      if (Boolean(newValue) && value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      let number = "";
      if (value.includes(".")) {
        let num1 = value.split(".")[0].substring(0, 2);
        let num2 = value.split(".")[1].substring(0, 2);
        number = `${num1}.${num2}`;
      } else if (+value == 100) {
        number = 100;
      } else if (+value == 1000) {
        number = 100;
      } else {
        number = value.substring(0, 2);
      }
      const result = number;
      this.$nextTick(() => (this.personScoreData.MaxScore = result));
    },
    "personScoreData.MinScore"(newValue) {
      let value = newValue.toString();

      if (Boolean(newValue) && value.substring(0, 1) == "0") {
        value = value.substring(1);
      }
      let number = "";
      if (value.includes(".")) {
        let num1 = value.split(".")[0].substring(0, 2);
        let num2 = value.split(".")[1].substring(0, 2);
        number = `${num1}.${num2}`;
      } else if (+value == 100) {
        number = 100;
      } else if (+value == 1000) {
        number = 100;
      } else {
        number = value.substring(0, 2);
      }
      const result = number;
      this.$nextTick(() => (this.personScoreData.MinScore = result));
    },
  },
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  background: #0379fd;
  color: #fff;
}
div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 300px;
}

.code-dtc {
  display: grid;
  grid-template-columns: 60px minmax(400px, 525px);
}
.input-group {
  margin-bottom: 10px;
}
.main-grid-dtc {
  display: grid;
  grid-template-columns: 350px 350px 1fr;
  column-gap: 5px;
}
.main-grid3-dtc {
  display: grid;
  grid-template-columns: 350px 350px 1fr;
}

::v-deep
  #ruralNursingDataGraduScoreOne
  > .modal-dialog
  > .modal-content
  > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}

.will-graduate {
  display: grid;
  grid-template-columns: 150px 90px 24px 12.5px 60px 24px;
  .word {
    margin-top: 7px;
    text-align: center;
  }
  .word2 {
    border-radius: 0.25rem;
    text-align: center;
  }
}

::v-deep main .custom-select {
  height: 37.5px;
  font-size: 16px !important;
}
</style>
